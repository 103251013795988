import React from "react";
import PropTypes from "prop-types";
import "./Home.css";
import heroImage from "../assets/images/heroimage@2x.png";
import phoneButton from "../assets/images/phonebutton.svg";
import lineButton from "../assets/images/linebutton.svg";
import whatsappButton from "../assets/images/whatsappbutton.svg";
import messengerButton from "../assets/images/messengerbutton.svg";

const Home = ({ className = "" }) => {
  return (
    <div className={`home ${className}`}>
      <b className="title">ช่างกุญแจพัทยา</b>
      <div className="subtitle">ความปลอดภัยที่คุณวางใจ</div>
      <div className="container">
        ยินดีต้อนรับสู่บริการช่างกุญแจพัทยา
        ที่นี่เรามุ่งมั่นที่จะให้บริการที่รวดเร็ว ปลอดภัย และเชื่อถือได้
        เพื่อให้คุณมั่นใจในความปลอดภัยของบ้าน รถ และธุรกิจของคุณ
        ไม่ว่าคุณจะล็อครถทิ้งไว้ ลืมกุญแจบ้าน หรือมีปัญหาเกี่ยวกับระบบล็อค
        เรามีช่างกุญแจมืออาชีพพร้อมให้บริการตลอด 24 ชั่วโมง
      </div>
      <div className="servicelist">
        <p className="p">บริการของเรา:</p>
        <ul className="ul">
          <li className="li">เปิดประตูบ้านและรถยนต์</li>
          <li className="li">ติดตั้งและซ่อมแซมระบบล็อค</li>
          <li className="li">ทำกุญแจใหม่และเปลี่ยนลูกบิด</li>
          <li>บริการเปิดเซฟและตู้เซฟ</li>
        </ul>
      </div>
      <div className="div">
        <p className="p">
          เราใส่ใจในทุกความต้องการของลูกค้า
          และใช้เทคโนโลยีและอุปกรณ์ที่ทันสมัยเพื่อให้บริการที่ดีที่สุด
          โทรหาเราวันนี้เพื่อขอคำปรึกษาและบริการที่รวดเร็วและมีประสิทธิภาพ
        </p>
      </div>
      <img className="heroimage-icon" alt="Hero" src={heroImage} />
      <a className="telbutton" href="tel:0876094997">โทร.0876094997</a>
      <div className="linkcontact">
        <a href="tel:0876094997">
          <img className="phonebutton-icon" alt="Phone" src={phoneButton} />
        </a>
        <a href="https://line.me/ti/p/KipFuRfqY_" target="_blank" rel="noopener noreferrer">
          <img className="linebutton-icon" alt="Line" src={lineButton} />
        </a>
        <a href="https://api.whatsapp.com/send?phone=66876094997" target="_blank" rel="noopener noreferrer">
          <img className="whatsappbutton-icon" alt="WhatsApp" src={whatsappButton} />
        </a>
        <a href="https://familycenter.facebook.com/invite/p/WAGIU02o_rUo44jUESFZd2KNyek0o/" target="_blank" rel="noopener noreferrer">
          <img className="messengerbutton-icon" alt="Messenger" src={messengerButton} />
        </a>
      </div>
    </div>
  );
};

Home.propTypes = {
  className: PropTypes.string,
};

export default Home;
