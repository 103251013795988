import PropTypes from "prop-types";
import "./Service.css";
import logo from "../assets/images/logo@2x.webp"; // นำเข้ารูปภาพ

const Service = ({ className = "" }) => {
  return (
    <div className={`service1 ${className}`}>
      <img className="logo-icon" loading="lazy" alt="Logo" src={logo} /> {/* ใช้รูปภาพที่นำเข้า */}
      <div className="service-details-wrapper">
        <div className="service-details">
          <h1 className="service1title">บริการของเรา</h1>
          <div className="description">
            <p className="p3">
              หากคุณเคยประสบปัญหาเกี่ยวกับกุญแจไม่ว่าจะเป็นการลืมกุญแจไว้ในรถหรือต้องการติดตั้งระบบล็อกใหม่
            </p>
            <p className="p4">{`สำหรับบ้านหรือสำนักงานของคุณ เรามีคำตอบสำหรับคุณ! `}</p>
            <p className="locksmith-pattaya">
              <span>{`ที่ `}</span>
              <span className="locksmith-pattaya1">{`Locksmith Pattaya `}</span>
              <span className="span">
                เรามีทีมงานมืออาชีพพร้อมให้บริการด้านกุญแจทุกประเภท
              </span>
            </p>
            <p className="p5">
              ในพัทยาและพื้นที่ใกล้เคียง ด้วยความรวดเร็ว ปลอดภัย
              และราคาที่เหมาะสม
            </p>
            <p className="blank-line">&nbsp;</p>
            <p className="locksmith-pattaya2">
              <span className="span1">{`ที่ `}</span>
              <span className="locksmith-pattaya3">Locksmith Pattaya</span>
              <span>
                {" "}
                เราเข้าใจว่าปัญหาด้านกุญแจสามารถเกิดขึ้นได้ทุกเมื่อ
                เราจึงพร้อมให้บริการที่หลากหลายเพื่อตอบสนองความต้องการของคุณ
              </span>
            </p>
            <p className="blank-line1">&nbsp;</p>
            <ul className="ul2">
              <li className="li6">
                <span>บริการเปิดประตูฉุกเฉิน</span>
              </li>
              <li className="li7">
                <span>การติดตั้งระบบล๊อคใหม่</span>
              </li>
              <li className="li8">
                <span>การทำกุญแจสำรอง</span>
              </li>
              <li>
                <span>การซ่อมแซมกุญแจ</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

Service.propTypes = {
  className: PropTypes.string,
};

export default Service;
