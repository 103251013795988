import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import translation files
import enTranslation from './translations/en.json';
import thTranslation from './translations/th.json';
import koTranslation from './translations/ko.json';
import zhTranslation from './translations/zh.json';
import deTranslation from './translations/de.json';
import frTranslation from './translations/fr.json';
import esTranslation from './translations/es.json';
import itTranslation from './translations/it.json';
import ruTranslation from './translations/ru.json';
import viTranslation from './translations/vi.json';
import loTranslation from './translations/lo.json';
import myTranslation from './translations/my.json';

// Configure i18next
i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    th: { translation: thTranslation },
    ko: { translation: koTranslation },
    zh: { translation: zhTranslation },
    de: { translation: deTranslation },
    fr: { translation: frTranslation },
    es: { translation: esTranslation },
    it: { translation: itTranslation },
    ru: { translation: ruTranslation },
    vi: { translation: viTranslation },
    lo: { translation: loTranslation },
    my: { translation: myTranslation },
  },
  lng: 'th', // Default language
  fallbackLng: 'th',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
