import React from 'react';
import './App.css';
import Home from './components/Home';
import Service from './components/Service';
//import Emergency from './components/Emergency';

import './global.css'; // นำเข้า global styles

function App() {
  return (
    <div className="App">
      <Home />
      <Service />
    </div>
  );
}

export default App;
